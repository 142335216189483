.ql-editor {
  min-height: 18em;
}

select {
  padding: 5px;
  border: 1px solid #dddddd;
  border-radius: 5px;
}

.rdt_Table {
  min-height: 300px;
}

.rdt_TableRow:last-child {
  border-bottom: 1px solid #dddddd;
}

.documents i {
  font-size: 5em;
}

.signature-img {
  width: 150px;
  height: 100px;
  object-fit: contain;
}

.lable-stick {
  white-space: nowrap;
}

.print_button button {
  display: inline-block;
  font-weight: 400;
  line-height: 1.53;
  color: #697a8d;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.4375rem 1.25rem;
  font-size: 0.9375rem;
  border-radius: 0.375rem;
  transition: all 0.2s ease-in-out;
  color: #fff;
  background-color: #4c86c6;
  border-color: #4c86c6;
  box-shadow: 0 0.125rem 0.25rem 0 rgba(105, 108, 255, 0.4);
}

.img_for_customer_print {
  display: none !important;
}

@media print {
  .img_for_customer_print {
    display: block !important;
  }
  .card_height {
    height: 100vh;
  }

  .margin_of_footer {
    margin-top: 280px !important;
    height: 100vh !important;
  }
}
